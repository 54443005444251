import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import './App.css';
import SuspendersIcon from './assets/suspenders-white.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { BrowserRouter as Router, Route } from "react-router-dom";
import WhatIDidApp from './apps/whatidid/App';
import Home from './Home';

class App extends Component {
  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: '#424242'
        },
        secondary: {
          main: '#ffffff'
        }
      }
    });

    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            {this.renderNav()}
            <div className='container' style={{ paddingTop: '30px', marginLeft: '70px', marginRight: '70px' }}>
              <Route exact path="/" component={Home} />
              <Route exact path="/whatidid" component={WhatIDidApp} />
            </div>
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }

  renderNav(styles) {
    return (
      <AppBar position="static">
        <Toolbar>
          <Link href="/">
            <IconButton
                  aria-haspopup="true"
                  color="inherit"
                >
                <img src={SuspendersIcon} height="30px" alt="" />
            </IconButton>
          </Link>
          <Link href="/" variant="title" color="secondary" underline="none">
            suspenders.dev
          </Link>
        </Toolbar>
      </AppBar>
    );
  }
}

export default App;
