import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withTheme } from '@material-ui/core/styles';
import AppCard from './components/AppCard';

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Grid container spacing={24}>
          <Grid item xs={4}>
            <AppCard url={'/whatidid'} name={'What I Did'} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTheme()(Home);
