import React, { Component } from 'react';
import { Link as BrowserLink } from "react-router-dom";
import { withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

class AppCard extends Component {
  render() {
    return (

        <Button
          variant="contained" color="primary"
          style={{ height: '150px', width: '200px' }}
          component={BrowserLink}
          to={this.props.url}
          >
          {this.props.name}
        </Button>
    );
  }
}

export default withTheme()(AppCard);
